import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";
import {on, trigger} from "@elements/dom-utils";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init({
        plugins: ['zoom', 'video'],
        zoom: true,
        video: true
    }
);

import * as asyncSlider from './scripts/async-slider.js';
asyncSlider.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();


import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init();

import * as scrollSlider from './scripts/scroll-slider';
scrollSlider.init();
